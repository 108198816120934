import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Headline from '@components/atoms/Headline/Headline';
import { StaticQuery, graphql, Link } from 'gatsby';
import Paragraph from '@components/atoms/Paragraph/Paragraph';
import VisualImg from '@assets/images/image.svg';

import OfferDropdownSvg from '@assets/svg/offer_dropdown.svg';
import OfferTopLeftSvg from '@assets/svg/offer_top_left.svg';
import OfferCircleSvg from '@assets/svg/offer_circle.svg';

const StyledWrapper = styled.section`
  max-width: 1250px;
  margin: 50px auto 0 auto;
  position: relative;
  padding-bottom: 200px;

  @media (min-width: 1025px) {
    margin: 50px auto 0 auto;
  }

  .svg_top_left {
    display: none;
    @media (min-width: 1025px) {
      display: block;
      position: absolute;
      top: 0;
      left: 10%;
      transform: translateY(-100%);
    }
  }
  .svg_top_right {
    display: none;
    @media (min-width: 1025px) {
      display: block;
      position: absolute;
      top: 0;
      left: unset;
      right: 0;
      transform: translate(20%, -80%);
    }
  }
`;
const StyledImgWrapper = styled.div`
  position: relative;
`;
const cardAnim = keyframes`
    0%{
        opacity: 0;
        transform: translateY(50%);
    }
    100%{
        opacity: 1;
        transform: translateY(30%);
    }
`;

const HeadlineWrapper = styled.div`
  padding: 50px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-bottom: 30px;
  }
  p {
    max-width: 250px;
  }
  @media (min-width: 1025px) {
    padding: 0 0 5px 0;

    h2 {
      margin-bottom: 50px;
    }
    p {
      max-width: 100%;
      align-self: flex-start;
    }
  }
`;
const StyledDropdown = styled.div`
  display: none;
  @media (min-width: 1025px) {
    display: block;
    width: 350px;
    height: 400px;
    background-color: #59b4d0;
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0;
    border-radius: 100px 0 100px 0;
    padding: 30px 0px 30px 80px;
    transform: translateY(30%);
    animation: ${cardAnim} 0.6s ease-out forwards;
    box-shadow: -10px 10px 40px rgba(0, 0, 0, 0.2);

    .svg_middle_left {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
      width: 100px;
    }
  }
`;
const SVGWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: auto;
    a {
      .green_overlay {
        transition: opacity 0.2s ease-out;
      }
      circle {
        @media (max-width: 576px) {
          r: 25;
        }
      }
      g {
        transform: scale(1.3);
      }
    }
    a:hover {
      .green_overlay {
        opacity: 1;
      }
    }

    .link-one:hover ~ .dropdown1 {
      opacity: 1;
    }
    .green_overlay {
      opacity: 0;
      cursor: pointer;
    }

    .dostepny {
      fill: #3ccb3e;
    }
    .zarezerwowany {
      fill: #e6b43e;
    }
    .zakupiony {
      fill: #ff7777;
    }
  }
`;

const StyledTitle = styled(Paragraph)`
  font-size: 32px;
  color: #fff;
  font-weight: ${({ theme }) => theme.bold};
  margin-bottom: 20px;
`;
const StyledParagraph = styled(Paragraph)`
  color: #fff;
  font-weight: ${({ theme }) => theme.medium};

  &.isBold {
    font-weight: ${({ theme }) => theme.bold};
    margin-left: 10px;
  }
`;
const StatusSpan = styled.span`
  font-weight: ${({ theme }) => theme.medium};

  &.dostepny {
    color: #95fabc;
  }
  &.zarezerwowany {
    color: #e6b43e;
  }
  &.zakupiony {
    color: #ff7777;
  }
`;
const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
`;
const StyledListItem = styled.li`
  color: #fff;
  display: flex;
  font-weight: ${({ theme }) => theme.regular};
  padding-bottom: 5px;
`;
const StyledPrice = styled.div`
  margin: 10px 0;
`;
const StyledPriceValue = styled.p`
  font-size: ${({ theme }) => theme.headingS};
  font-weight: ${({ theme }) => theme.bold};
  color: #fff;
`;
const StyledPriceValueSpan = styled.span`
  font-size: ${({ theme }) => theme.bodyM};
`;
const StyledButton = styled.button`
  background-color: transparent;
  border: 1px solid #fff;
  width: 120px;
  height: 40px;
  border-radius: 8px;
  outline: none;
  justify-self: center;

  a {
    color: #fff;
    text-decoration: none;
    font-family: ${({ theme }) => theme.fontFamily};
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
`;

const StyledLegend = styled.div`
  display: flex;
  padding: 15px 15px;
  @media (min-width: 1025px) {
    padding: 15px 0;
  }
`;

const StyledLegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (min-width: 1025px) {
    flex-direction: row;
  }
`;
const StyledLegendDot = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  @media (min-width: 1025px) {
    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: 15px;
    }
  }
`;

const LegendDot = styled.span`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #95fabc;
  margin-right: 5px;
`;

const LegendDotReserved = styled(LegendDot)`
  background-color: #e6b43e;
`;
const LegendDotBought = styled(LegendDot)`
  background-color: #ff7777;
`;
const StyledCheckboxWrapper = styled.div`
  display: inline-block;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HiddenCheckbox = styled.input`
  border: 0;
  height: 15px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 15px;
  opacity: 0;
  cursor: pointer;
`;

const Checkmark = styled.span`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #95fabc;
  border-radius: 3px;

  &::after {
    content: '';
    position: absolute;
    display: none;
    left: 50%;
    top: 50%;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;
const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  input:checked ~ span {
    background-color: #95fabc;
  }
  input:checked ~ span::after {
    display: block;
  }
`;
const ShowAvailable = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const StyledBgLine = styled.span`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 1px;
  height: 70%;
  background-color: ${({ theme }) => theme.grey};
  transform: translateX(-50%);
  z-index: -1;
`;

const StyledBgLineLeft = styled(StyledBgLine)`
  left: 20%;
  @media (min-width: 1025px) {
    left: 35%;
  }
`;
const StyledBgLineRight = styled(StyledBgLine)`
  left: 80%;

  @media (min-width: 1025px) {
    left: 65%;
  }
`;

const Visualisation = () => {
  const [isDropdown, setDropdown] = useState<boolean>(false);
  const [linkIndex, setLinkIndex] = useState<number>(0);
  const [onlyAvailable, setOnlyAvailable] = useState<boolean>(false);

  const onMouseEnter = (i) => {
    setDropdown(true);
    setLinkIndex(parseInt(i));
  };
  const onMouseLeave = (i) => {
    setDropdown(false);
    setLinkIndex(0);
  };
  const handleChange = () => {
    setOnlyAvailable(!onlyAvailable);
  };
  const hideVector = (houseId) => {
    if (onlyAvailable && houseId === 'zakupiony') {
      return null;
    } else if (onlyAvailable && houseId === 'zarezerwowany') {
      return null;
    } else {
      return true;
    }
  };

  let defaultWidth;

  if (typeof window !== `undefined`) {
    defaultWidth = window.innerWidth;
  }

  const useWindowSize = () => {
    const [width, setWidth] = useState({
      width: defaultWidth,
    });

    useEffect(() => {
      const handleResize = () =>
        setWidth({
          width: window.innerWidth,
        });

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    return width;
  };

  const { width } = useWindowSize();

  return (
    <StaticQuery
      query={graphql`
        {
          allDatoCmsLokale(sort: { order: ASC, fields: propertyNumber }) {
            edges {
              node {
                propertyNumber
                price
                name
                area
                parcelArea
                availability
                garage
                deliveryDate
              }
            }
          }
        }
      `}
      render={(data) => (
        <StyledWrapper id="houses">
          <HeadlineWrapper>
            <Headline as="h2" $isMainColor $isHeadline2 data-aos="fade-down">
              Oferta domów
            </Headline>
            <Paragraph data-aos="fade-left" data-aos-delay="200">
              Wybierz interesujący Cię budynek, aby zobaczyć szczegóły oferty
            </Paragraph>
          </HeadlineWrapper>
          <StyledImgWrapper>
            <img
              src={VisualImg}
              alt="Wizualizacja"
              width="100%"
              height="auto"
            />
            <SVGWrapper>
              <svg
                id="svg-wrapper"
                width="1640"
                height="923"
                viewBox="0 0 1640 923"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {hideVector(
                  data.allDatoCmsLokale.edges[0].node.availability
                ) && (
                  <Link
                    to="/lokal1"
                    id="house-link"
                    className="lokal-link"
                    onMouseEnter={(i) => onMouseEnter(1)}
                    onMouseLeave={() => onMouseLeave}
                    link-index={linkIndex}
                  >
                    <path
                      className={`green_overlay ${data.allDatoCmsLokale.edges[0].node.availability}`}
                      d="M407.5 402L375 420.5V423L398.5 413.5L409 497.5L113 655.5L614.5 663.5V634L630.5 622L604.5 611.5L712.5 539L709 420.5L669 379.5L646 374.5L589 331L528 356V344L511 340.5L506 341.5L443 329.5L407.5 402Z"
                      fill="#04C200"
                      fill-opacity="0.4"
                    />
                    <circle
                      className={
                        data.allDatoCmsLokale.edges[0].node.availability
                      }
                      cx="557.5"
                      cy="355.5"
                      r={width <= 1025 ? '25' : '16.5'}
                      fill="#3CCB3E"
                    />
                    <path
                      d="M557.263 362V350.996H557.228L553.87 353.413V351.743L557.245 349.317H558.792V362H557.263Z"
                      fill="white"
                    />
                  </Link>
                )}
                {hideVector(
                  data.allDatoCmsLokale.edges[1].node.availability
                ) && (
                  <Link
                    to="/lokal2"
                    id="house-link"
                    className="lokal-link"
                    onMouseEnter={(i) => onMouseEnter(2)}
                    onMouseLeave={() => onMouseLeave}
                    link-index={linkIndex}
                  >
                    <path
                      className={`green_overlay ${data.allDatoCmsLokale.edges[1].node.availability}`}
                      d="M528 344L514.5 341L536 295L577.5 303.5L590 299.5L601.5 303.5V308L629.5 313.5L665.5 298.5V278.5L672.5 275L687.5 278.5V306.5L723 332.5L752 338.5L784 379.5L794.5 388.5H797.5L799 478.5L713 538.5L709 421L669 379.5L645.5 374L589 331L528 356V344Z"
                      fill="#04C200"
                      fill-opacity="0.4"
                    />
                    <circle
                      className={
                        data.allDatoCmsLokale.edges[1].node.availability
                      }
                      cx="649.5"
                      cy="312.5"
                      r={width <= 1025 ? '25' : '16.5'}
                      fill="#3CCB3E"
                    />
                    <path
                      d="M644.949 309.974C644.949 308.866 645.348 307.946 646.145 307.214C646.941 306.476 647.938 306.106 649.133 306.106C650.264 306.106 651.222 306.443 652.007 307.117C652.792 307.791 653.185 308.614 653.185 309.587C653.185 310.29 652.991 310.967 652.604 311.617C652.218 312.268 651.491 313.144 650.425 314.245L647.217 317.559V317.594H653.396V319H645.028V317.91L649.493 313.208C650.343 312.312 650.905 311.632 651.181 311.169C651.462 310.706 651.603 310.211 651.603 309.684C651.603 309.074 651.356 308.556 650.864 308.128C650.378 307.7 649.786 307.486 649.089 307.486C648.345 307.486 647.724 307.724 647.226 308.198C646.728 308.673 646.479 309.265 646.479 309.974V309.982H644.949V309.974Z"
                      fill="white"
                    />
                  </Link>
                )}
                {hideVector(
                  data.allDatoCmsLokale.edges[2].node.availability
                ) && (
                  <Link
                    to="/lokal3"
                    id="house-link"
                    className="lokal-link"
                    onMouseEnter={(i) => onMouseEnter(3)}
                    onMouseLeave={() => onMouseLeave}
                    link-index={linkIndex}
                  >
                    <path
                      className={`green_overlay ${data.allDatoCmsLokale.edges[2].node.availability}`}
                      d="M621.5 263.5L601.5 307.5L629.5 313.5L665 298.5L665.5 278L672.5 275L687 279L688 306L723.5 332.5L752 338.5L785 378.5L797.5 388.5L799 478L804.5 477L860 439.5L861.5 345.5L855 340.5L827.5 300.5L799.5 295L753 261.5L730.615 270.5V258L718.5 255.5L702.5 262V270.143L690.5 268L676.5 273L621.5 263.5Z"
                      fill="#04C200"
                      fill-opacity="0.4"
                    />
                    <circle
                      className={
                        data.allDatoCmsLokale.edges[2].node.availability
                      }
                      cx="730.5"
                      cy="276.5"
                      r={width <= 1025 ? '25' : '16.5'}
                      fill="#3CCB3E"
                    />
                    <path
                      d="M728.612 277.146V275.811H730.133C730.859 275.811 731.454 275.603 731.917 275.187C732.38 274.765 732.611 274.231 732.611 273.587C732.611 272.942 732.397 272.43 731.97 272.049C731.542 271.668 730.936 271.478 730.15 271.478C729.424 271.478 728.832 271.671 728.375 272.058C727.918 272.444 727.654 272.972 727.584 273.64H726.063C726.151 272.562 726.567 271.703 727.312 271.064C728.062 270.426 729.031 270.106 730.221 270.106C731.363 270.106 732.312 270.414 733.068 271.029C733.824 271.645 734.202 272.427 734.202 273.376C734.202 274.167 733.968 274.832 733.499 275.371C733.03 275.91 732.397 276.25 731.601 276.391V276.426C732.562 276.484 733.326 276.792 733.895 277.349C734.463 277.899 734.747 278.62 734.747 279.511C734.747 280.22 734.548 280.858 734.149 281.427C733.751 281.995 733.212 282.435 732.532 282.745C731.853 283.056 731.1 283.211 730.273 283.211C728.961 283.211 727.903 282.883 727.101 282.227C726.298 281.57 725.867 280.709 725.809 279.643H727.329C727.399 280.305 727.695 280.838 728.217 281.242C728.738 281.641 729.418 281.84 730.256 281.84C731.094 281.84 731.782 281.626 732.321 281.198C732.866 280.771 733.139 280.226 733.139 279.563C733.139 278.819 732.875 278.23 732.348 277.797C731.826 277.363 731.111 277.146 730.203 277.146H728.612Z"
                      fill="white"
                    />
                  </Link>
                )}
                {hideVector(
                  data.allDatoCmsLokale.edges[3].node.availability
                ) && (
                  <Link
                    to="/lokal4"
                    id="house-link"
                    className="lokal-link"
                    onMouseEnter={(i) => onMouseEnter(4)}
                    onMouseLeave={() => onMouseLeave}
                    link-index={linkIndex}
                  >
                    <path
                      className={`green_overlay ${data.allDatoCmsLokale.edges[3].node.availability}`}
                      d="M677 272.5H674.5L691 236.5L728.5 243.5L744 238L755.5 241V248L775.5 251.5L805.5 238V221.5L817.5 217L828 219.5V232.5L880 268.5L889 269.5L894.5 279L932.5 305L935 309.5L925 315.5V321L913 323.5L912 375.5L908 383L953 393.5V427L899 415L861.5 438L862.5 345.5L855 339.5L827 300.5L800 295L752.5 261.5L744 265L730.5 270.5L731 258L718 255.5L702 262.5V270L689.5 268L677 272.5Z"
                      fill="#04C200"
                      fill-opacity="0.4"
                    />
                    <circle
                      className={
                        data.allDatoCmsLokale.edges[3].node.availability
                      }
                      cx="796.5"
                      cy="249.5"
                      r={width <= 1025 ? '25' : '16.5'}
                      fill="#3CCB3E"
                    />
                    <path
                      d="M798.058 256V253.363H791.686V251.931C792.23 250.917 792.922 249.76 793.76 248.459C794.604 247.158 795.767 245.444 797.249 243.317H799.587V251.975H801.38V253.363H799.587V256H798.058ZM793.25 251.939V251.975H798.058V244.636H798.031C795.811 247.806 794.217 250.24 793.25 251.939Z"
                      fill="white"
                    />
                  </Link>
                )}
                {hideVector(
                  data.allDatoCmsLokale.edges[4].node.availability
                ) && (
                  <Link
                    to="/lokal5"
                    id="house-link"
                    className="lokal-link"
                    onMouseEnter={(i) => onMouseEnter(5)}
                    onMouseLeave={() => onMouseLeave}
                    link-index={linkIndex}
                  >
                    <path
                      className={`green_overlay ${data.allDatoCmsLokale.edges[4].node.availability}`}
                      d="M912 383L914 324L925.5 321V315.5L935 308.5L932 305L894.5 278.5L889 270L879.5 268.5L828 232V227.5L830.5 226L852 176L913 184V177.5L928 170.5L939.5 172.5L941 158.5L944 157.5L956 159.5V176L982.5 193.5L1029.5 200L1044 235L1039.5 319.5L1002 345.5H997.5L969 365L998.5 371.5L997.5 376L981.5 373L953 393L912 383Z"
                      fill="#04C200"
                      fill-opacity="0.4"
                    />
                    <circle
                      className={
                        data.allDatoCmsLokale.edges[4].node.availability
                      }
                      cx="941.5"
                      cy="193.5"
                      r={width <= 1025 ? '25' : '16.5'}
                      fill="#3CCB3E"
                    />
                    <path
                      d="M941.388 200.211C940.175 200.211 939.161 199.868 938.347 199.183C937.538 198.497 937.102 197.615 937.037 196.537H938.593C938.663 197.211 938.959 197.762 939.48 198.189C940.002 198.611 940.644 198.822 941.405 198.822C942.267 198.822 942.967 198.553 943.506 198.014C944.051 197.475 944.323 196.777 944.323 195.922C944.323 195.066 944.051 194.366 943.506 193.821C942.967 193.271 942.275 192.995 941.432 192.995C940.84 192.995 940.304 193.121 939.823 193.373C939.343 193.625 938.974 193.974 938.716 194.419H937.23L937.925 187.317H945.185V188.724H939.226L938.839 192.854H938.874C939.161 192.474 939.554 192.175 940.052 191.958C940.556 191.741 941.109 191.633 941.713 191.633C942.932 191.633 943.934 192.034 944.719 192.837C945.51 193.634 945.905 194.647 945.905 195.878C945.905 197.149 945.483 198.189 944.64 198.998C943.796 199.807 942.712 200.211 941.388 200.211Z"
                      fill="white"
                    />
                  </Link>
                )}
                {hideVector(
                  data.allDatoCmsLokale.edges[5].node.availability
                ) && (
                  <Link
                    to="/lokal6"
                    id="house-link"
                    className="lokal-link"
                    onMouseEnter={(i) => onMouseEnter(6)}
                    onMouseLeave={() => onMouseLeave}
                    link-index={linkIndex}
                  >
                    <path
                      className={`green_overlay ${data.allDatoCmsLokale.edges[5].node.availability}`}
                      d="M1075 294.5L1039.5 319.5L1043 232.5L1029.5 200.5L982 194L956 176L938.5 172.5L927.5 170.5L912.5 178V183.5L887.5 180L896 159L940.5 165V159L944 157.5L948.5 154.5L960.5 157.5V167L969.5 168L992 159L993.5 144L1000 142L1010 144V163.5L1027.5 176L1067.5 181L1081 215L1075 294.5Z"
                      fill="#04C200"
                      fill-opacity="0.4"
                    />
                    <circle
                      className={
                        data.allDatoCmsLokale.edges[5].node.availability
                      }
                      cx="992.5"
                      cy="170.5"
                      r={width <= 1025 ? '25' : '16.5'}
                      fill="#3CCB3E"
                    />
                    <path
                      d="M992.616 177.22C991.667 177.22 990.829 176.974 990.103 176.481C989.376 175.983 988.816 175.277 988.424 174.363C988.008 173.402 987.8 172.239 987.8 170.874C987.8 168.735 988.23 167.071 989.092 165.882C989.959 164.692 991.169 164.098 992.722 164.098C993.8 164.098 994.72 164.396 995.481 164.994C996.243 165.592 996.727 166.389 996.932 167.385H995.323C995.153 166.811 994.831 166.354 994.356 166.014C993.882 165.668 993.331 165.495 992.704 165.495C991.644 165.495 990.823 165.949 990.243 166.857C989.663 167.766 989.358 169.063 989.329 170.751H989.355C989.643 170.13 990.108 169.632 990.753 169.257C991.397 168.882 992.112 168.694 992.897 168.694C994.104 168.694 995.106 169.093 995.903 169.89C996.706 170.687 997.107 171.686 997.107 172.887C997.107 174.141 996.683 175.178 995.833 175.998C994.989 176.812 993.917 177.22 992.616 177.22ZM990.542 174.97C991.104 175.532 991.79 175.813 992.599 175.813C993.407 175.813 994.093 175.535 994.655 174.979C995.218 174.422 995.499 173.742 995.499 172.939C995.499 172.107 995.229 171.425 994.69 170.892C994.151 170.353 993.463 170.083 992.625 170.083C991.787 170.083 991.09 170.35 990.533 170.883C989.982 171.416 989.707 172.087 989.707 172.896C989.707 173.71 989.985 174.401 990.542 174.97Z"
                      fill="white"
                    />
                  </Link>
                )}
                {hideVector(
                  data.allDatoCmsLokale.edges[6].node.availability
                ) && (
                  <Link
                    to="/lokal7"
                    id="house-link"
                    className="lokal-link"
                    onMouseEnter={(i) => onMouseEnter(7)}
                    onMouseLeave={() => onMouseLeave}
                    link-index={linkIndex}
                  >
                    <path
                      className={`green_overlay ${data.allDatoCmsLokale.edges[6].node.availability}`}
                      d="M1075 294L1081 215L1067.5 181L1028 175L1010.5 163.5V144L1000 141.5L993 144L992.7 148.5L992 159L969.5 168L960.5 167L961 158L949.5 154.5L940 159V163.5H931.5L940 143L992.7 148.5L993 144L1000 141.5L1006 138.5L1017.5 140V148.5L1035 141.5L1058 157L1104 161L1116 195L1111 274L1075 294Z"
                      fill="#04C200"
                      fill-opacity="0.4"
                    />
                    <circle
                      className={
                        data.allDatoCmsLokale.edges[6].node.availability
                      }
                      cx="1034.5"
                      cy="150.5"
                      r={width <= 1025 ? '25' : '16.5'}
                      fill="#3CCB3E"
                    />
                    <path
                      d="M1030.76 157L1036.5 145.759V145.724H1029.67V144.317H1038.06V145.785L1032.42 157H1030.76Z"
                      fill="white"
                    />
                  </Link>
                )}
                {hideVector(
                  data.allDatoCmsLokale.edges[7].node.availability
                ) && (
                  <Link
                    to="/lokal8"
                    id="house-link"
                    className="lokal-link"
                    onMouseEnter={(i) => onMouseEnter(8)}
                    onMouseLeave={() => onMouseLeave}
                    link-index={linkIndex}
                  >
                    <path
                      // className={`green_overlay ${data.allDatoCmsLokale.edges[7].node.availability}`}
                      className={`green_overlay zarezerwowany`}
                      d="M1137 261.5L1111 274L1116 195L1104 161L1057.5 156.5L1034.5 141.5L1018.5 148.5L1017.5 140.5L1006 138.5L993 144.5L992.5 148.5L970 146.5L977.5 129L1021 133.5V126.5L1030 123.5L1038 125V136H1046L1065.5 127.5L1066.5 114.5L1072 112.5L1081.5 113.5L1080.5 127.5L1105 142.5L1136 146L1143 166.5L1162 179.5L1144 190.5L1142 240L1172.5 243.5L1171 266.5L1137 261.5Z"
                      fill="#04C200"
                      fill-opacity="0.4"
                    />
                    <circle
                      // className={
                      //   data.allDatoCmsLokale.edges[7].node.availability
                      // }
                      className="zarezerwowany"
                      cx="1076.5"
                      cy="128.5"
                      r={width <= 1025 ? '25' : '16.5'}
                      fill="#3CCB3E"
                    />
                    <path
                      d="M1076.39 135.211C1075.05 135.211 1073.94 134.871 1073.08 134.191C1072.22 133.506 1071.79 132.633 1071.79 131.572C1071.79 130.781 1072.04 130.087 1072.54 129.489C1073.04 128.892 1073.7 128.499 1074.52 128.312V128.276C1073.83 128.065 1073.29 127.708 1072.89 127.204C1072.49 126.694 1072.29 126.105 1072.29 125.438C1072.29 124.482 1072.68 123.688 1073.45 123.056C1074.23 122.423 1075.21 122.106 1076.39 122.106C1077.57 122.106 1078.55 122.423 1079.31 123.056C1080.09 123.683 1080.47 124.479 1080.47 125.446C1080.47 126.108 1080.28 126.691 1079.89 127.195C1079.49 127.699 1078.95 128.06 1078.26 128.276V128.312C1079.08 128.505 1079.74 128.9 1080.24 129.498C1080.74 130.096 1080.99 130.787 1080.99 131.572C1080.99 132.633 1080.56 133.506 1079.7 134.191C1078.84 134.871 1077.74 135.211 1076.39 135.211ZM1076.39 133.831C1077.26 133.831 1077.98 133.608 1078.53 133.163C1079.09 132.718 1079.38 132.149 1079.38 131.458C1079.38 130.761 1079.09 130.189 1078.53 129.744C1077.98 129.299 1077.26 129.076 1076.39 129.076C1075.51 129.076 1074.8 129.302 1074.23 129.753C1073.68 130.198 1073.4 130.767 1073.4 131.458C1073.4 132.149 1073.68 132.718 1074.24 133.163C1074.81 133.608 1075.52 133.831 1076.39 133.831ZM1076.39 127.688C1077.13 127.688 1077.73 127.491 1078.2 127.099C1078.67 126.706 1078.91 126.202 1078.91 125.587C1078.91 124.966 1078.68 124.459 1078.21 124.066C1077.74 123.668 1077.13 123.469 1076.39 123.469C1075.64 123.469 1075.04 123.668 1074.57 124.066C1074.1 124.459 1073.87 124.966 1073.87 125.587C1073.87 126.202 1074.1 126.706 1074.57 127.099C1075.04 127.491 1075.64 127.688 1076.39 127.688Z"
                      fill="white"
                    />
                  </Link>
                )}
              </svg>
              {data.allDatoCmsLokale.edges
                .filter((house, index) => {
                  if (!linkIndex) {
                    return false;
                  } else if (house.node.name.includes(linkIndex)) {
                    return true;
                  }
                  return false;
                })
                .map((house, index) => (
                  <StyledDropdown
                    key={index}
                    data-id={index}
                    className={
                      !isDropdown
                        ? `dropdown${house.node.propertyNumber}`
                        : `dropdown${linkIndex} open`
                    }
                  >
                    <StyledTitle>{house.node.name}</StyledTitle>
                    <StyledParagraph>
                      Status:{' '}
                      <StatusSpan className={house.node.availability}>
                        {house.node.availability === 'dostepny'
                          ? 'dostępny'
                          : house.node.availability}
                      </StatusSpan>
                    </StyledParagraph>
                    <StyledList>
                      <StyledListItem>
                        Powierzchnia:{' '}
                        <StyledParagraph className="isBold">{`${house.node.area} m2`}</StyledParagraph>
                      </StyledListItem>
                      <StyledListItem>
                        Powierzchnia działki:{' '}
                        <StyledParagraph className="isBold">{`${house.node.parcelArea} m2`}</StyledParagraph>
                      </StyledListItem>
                      <StyledListItem>
                        Garaż:{' '}
                        <StyledParagraph className="isBold">
                          {house.node.garage}
                        </StyledParagraph>
                      </StyledListItem>
                      <StyledListItem>
                        Termin oddania:{' '}
                        <StyledParagraph className="isBold">
                          {house.node.deliveryDate}
                        </StyledParagraph>
                      </StyledListItem>
                    </StyledList>
                    <StyledPrice>
                      <StyledParagraph>Cena:</StyledParagraph>
                      <StyledPriceValue>
                        {`${house.node.price} zł `}
                        <StyledPriceValueSpan>(brutto)</StyledPriceValueSpan>
                      </StyledPriceValue>
                    </StyledPrice>
                    <StyledButton>
                      <Link to={`/lokal${house.node.propertyNumber}`}>
                        Zobacz więcej
                      </Link>
                    </StyledButton>
                    <img
                      src={OfferDropdownSvg}
                      alt=""
                      className="svg_middle_left"
                    />
                  </StyledDropdown>
                ))}
            </SVGWrapper>
          </StyledImgWrapper>
          <StyledLegend>
            <StyledLegendWrapper>
              <StyledLegendDot data-aos="fade-up">
                <LegendDot></LegendDot>
                <Paragraph>Dostępne</Paragraph>
              </StyledLegendDot>
              <StyledLegendDot data-aos="fade-up" data-aos-delay="200">
                <LegendDotReserved></LegendDotReserved>
                <Paragraph>Zarezerwowane</Paragraph>
              </StyledLegendDot>
              <StyledLegendDot data-aos="fade-up" data-aos-delay="400">
                <LegendDotBought></LegendDotBought>
                <Paragraph>Sprzedane</Paragraph>
              </StyledLegendDot>
            </StyledLegendWrapper>
            <StyledCheckboxWrapper>
              <CheckboxContainer>
                <CheckboxLabel>
                  <ShowAvailable
                    type="checkbox"
                    checked={onlyAvailable}
                    onChange={handleChange}
                  />
                  <Checkmark></Checkmark>
                  Pokaż tylko dostępne
                </CheckboxLabel>
              </CheckboxContainer>
            </StyledCheckboxWrapper>
          </StyledLegend>
          <StyledBgLine></StyledBgLine>
          <StyledBgLineLeft></StyledBgLineLeft>
          <StyledBgLineRight></StyledBgLineRight>
          <img src={OfferTopLeftSvg} alt="" className="svg_top_left" />
          <img src={OfferCircleSvg} alt="" className="svg_top_right" />
        </StyledWrapper>
      )}
    />
  );
};

export default Visualisation;
