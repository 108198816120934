import React, { FC, useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import styled, { css } from 'styled-components';
import axios from 'axios';
import Paragraph from '@components/atoms/Paragraph/Paragraph';
import ReCAPTCHA from 'react-google-recaptcha';

interface MyFormValues {
  firstName: string;
  email: string;
  phone: string;
  message: string;
}

interface StyledProps {
  $isNotRequired?: boolean;
  $isMessage?: boolean;
  $isCheckbox?: boolean;
}

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 20px 20px 20px;
  margin-bottom: 100px;
  position: relative;
  @media (min-width: 1025px) {
    width: 60%;
    background-color: #fff;
    margin: 50px 50px 50px 0;
    border-radius: 100px 0 100px 0;
    padding: 50px;
    form {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
`;

const FormWrapperLeft = styled.div`
  @media (min-width: 1025px) {
    width: 45%;
  }
`;
const FormWrapperRight = styled.div`
  @media (min-width: 1025px) {
    width: 45%;
  }
`;

const FormWrapperBottom = styled.div`
  @media (min-width: 1025px) {
    width: 100%;
    display: flex;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-bottom: 50px;
  display: none;
  justify-content: center;
  @media (min-width: 1025px) {
    display: flex;
  }
`;

const ButtonWrapperMobile = styled(ButtonWrapper)`
  display: none;
  @media (max-width: 1025px) {
    display: flex;
  }
`;

const StyledButton = styled.button`
  position: relative;
  width: 200px;
  height: 60px;
  background-color: #fff;
  outline: none;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.primary};
  font-weight: ${({ theme }) => theme.bold};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.bodyM};

  svg {
    margin-left: 10px;
  }

  @media (min-width: 1025px) {
    width: 130px;
    height: 40px;
    font-size: ${({ theme }) => theme.bodyS};
  }
`;

const StyledLabel = styled.label<StyledProps>`
  font-weight: ${({ theme }) => theme.bold};
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 50px;

  ${({ $isCheckbox }) =>
    $isCheckbox &&
    css`
      flex-direction: row;
      margin-left: 30px;
      font-weight: ${({ theme }) => theme.regular};
      font-size: ${({ theme }) => theme.bodyXS};

      &:first-child {
        margin-right: 20px;
      }

      input {
        position: absolute;
        height: auto !important;
        margin: 0 !important;
        top: 2px;
        left: -30px;
      }
    `}

  &::before {
    content: '*';
    position: absolute;
    top: 0;
    left: 0;
    color: ${({ theme }) => theme.primary};
    transform: translateX(-100%);

    ${({ $isNotRequired }) =>
      $isNotRequired &&
      css`
        display: none;
      `}
  }
  input {
    margin-top: 10px;
    padding-left: 70px;
    height: 70px;
    border: none;
    outline: none;
    background-color: ${({ theme }) => theme.lightGrey};
    border-radius: 15px;
    border: 2px solid ${({ theme }) => theme.lightGrey};
    font-size: ${({ theme }) => theme.bodyL};
    transition: all 0.2s ease-in;

    ${({ $isMessage }) =>
      $isMessage &&
      css`
        height: 140px;
        padding-left: 0;
      `}

    &:focus {
      border: 2px solid ${({ theme }) => theme.primary};
      box-shadow: 0 0 15px rgba(89, 180, 208, 0.2);
    }

    &:focus ~ svg path {
      fill: ${({ theme }) => theme.primary};
    }
  }
  textarea {
    margin-top: 10px;
    padding: 10px;
    height: 140px;
    border: none;
    outline: none;
    resize: none;
    background-color: ${({ theme }) => theme.lightGrey};
    border-radius: 15px;
    border: 2px solid ${({ theme }) => theme.lightGrey};
    font-size: ${({ theme }) => theme.bodyL};
    transition: border 0.2s ease-in, box-shadow 0.2s ease-in;

    &:focus {
      border: 2px solid ${({ theme }) => theme.primary};
      box-shadow: 0 0 15px rgba(89, 180, 208, 0.2);
    }

    @media (min-width: 1025px) {
      height: 370px;
    }
  }

  svg {
    position: absolute;
    top: 47px;
    left: 20px;
    path {
      transition: all 0.2s ease-in;
    }
  }
`;

const StyledRequiredInfo = styled.span`
  position: absolute;
  bottom: -20px;
  left: 0;
  font-size: ${({ theme }) => theme.bodyXS};

  span {
    color: ${({ theme }) => theme.primary};
  }
`;

const FormSubmitedWrapper = styled.div`
  background-color: ${({ theme }) => theme.lightGrey};
  border-radius: 20px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 0;
  right: 50%;
  transform: translate(50%, -50%);
  width: 300px;
  height: 150px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;
`;

const FormButtonClose = styled.button`
  width: 100px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 10px;
  background-color: #fff;
  margin-top: 15px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily};
`;

const ReCAPTCHAWrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorMessage = styled(Paragraph)`
  color: red;
  font-size: ${({ theme }) => theme.bodyXS};
`;

const ContactForm: FC<{}> = () => {
  const [isFormSubmited, setFormSubmited] = useState<boolean>(false);
  const [isRecaptchaTrue, setRecaptchaTrue] = useState<boolean>(false);
  const [openErrorMsg, setOpenErrorMsg] = useState<boolean>(false);

  let defaultWidth;
  if (typeof window !== `undefined`) {
    defaultWidth = window.innerWidth;
  }
  const useWindowSize = () => {
    const [width, setWidth] = useState({
      width: defaultWidth,
    });
    useEffect(() => {
      const handleResize = () =>
        setWidth({
          width: window.innerWidth,
        });
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    return width;
  };
  const { width } = useWindowSize();

  const initialValues: MyFormValues = {
    firstName: '',
    email: '',
    phone: '',
    message: '',
  };

  const isReCAPTCHAVerifed = () => {
    setRecaptchaTrue(true);
  };

  const isReCAPTCHAExpired = () => {
    setRecaptchaTrue(false);
  };

  return (
    <FormWrapper className="form-wrapper">
      {isFormSubmited && (
        <FormSubmited onClick={() => setFormSubmited(false)} />
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          if (!isRecaptchaTrue) {
            setOpenErrorMsg(true);
          } else {
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            axios
              .all([
                axios.post(
                  'https://formsubmit.co/ajax/65402912fe4e839a9fe37acf65c02b77',
                  {
                    name: values.firstName,
                    email: values.email,
                    phone: values.phone ? values.phone : 'Nie podano',
                    message: values.message,
                  }
                ),
                axios.post('https://formsubmit.co/ajax/bud-pol.biuro@wp.pl', {
                  name: values.firstName,
                  email: values.email,
                  phone: values.phone ? values.phone : 'Nie podano',
                  message: values.message,
                }),
              ])
              .then((response) => console.log('Wysłano email'))
              .catch((error) => {
                alert(
                  'Wystąpił błąd. Wyślij wiadomość ręcznie za pomocą podanego emaila'
                );
                setFormSubmited(false);
              });
            actions.setSubmitting(false);
            setFormSubmited(true);
            setOpenErrorMsg(false);
          }
        }}
      >
        <Form>
          <FormWrapperLeft>
            <StyledLabel
              htmlFor="firstName"
              data-aos="flip-down"
              data-aos-anchor=".form-wrapper"
            >
              Imię i nazwisko
              <Field id="firstName" name="firstName" required />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 20 20"
              >
                <path
                  id="ic_person_24px"
                  d="M14,14A5,5,0,1,0,9,9,5,5,0,0,0,14,14Zm0,2.5c-3.338,0-10,1.675-10,5V24H24V21.5C24,18.175,17.338,16.5,14,16.5Z"
                  transform="translate(-4 -4)"
                  fill="#e2e2e2"
                />
              </svg>
            </StyledLabel>

            <StyledLabel
              htmlFor="email"
              data-aos="flip-down"
              data-aos-anchor=".form-wrapper"
              data-aos-delay="200"
            >
              E-mail
              <Field id="email" name="email" required />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 20 19"
              >
                <path
                  id="ic_drafts_24px"
                  d="M21.99,8a1.982,1.982,0,0,0-.94-1.7L12,1,2.95,6.3A1.991,1.991,0,0,0,2,8V18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2ZM12,13,3.74,7.84,12,3l8.26,4.84Z"
                  transform="translate(-2 -1)"
                  fill="#e2e2e2"
                />
              </svg>
            </StyledLabel>
            <StyledLabel
              htmlFor="phone"
              $isNotRequired
              data-aos="flip-down"
              data-aos-anchor=".form-wrapper"
              data-aos-delay="400"
            >
              Numer telefonu
              <Field id="phone" name="phone" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 8.86 24"
              >
                <path
                  id="ic_call_end_24px"
                  d="M12,9a14.911,14.911,0,0,0-4.6.72v3.1a1.008,1.008,0,0,1-.56.9,11.511,11.511,0,0,0-2.66,1.85.982.982,0,0,1-.7.28,1,1,0,0,1-.71-.29L.29,13.08a.956.956,0,0,1-.29-.7,1,1,0,0,1,.29-.71,17.016,17.016,0,0,1,23.42,0,1.014,1.014,0,0,1,0,1.42l-2.48,2.48a1,1,0,0,1-.71.29,1.026,1.026,0,0,1-.7-.28,11.27,11.27,0,0,0-2.67-1.85,1,1,0,0,1-.56-.9V9.73A14.516,14.516,0,0,0,12,9Z"
                  transform="translate(-7 24) rotate(-90)"
                  fill="#e2e2e2"
                />
              </svg>
            </StyledLabel>
          </FormWrapperLeft>

          <FormWrapperRight>
            <StyledLabel
              htmlFor="message"
              $isMessage
              data-aos="flip-down"
              data-aos-anchor=".form-wrapper"
              data-aos-delay="600"
            >
              Wiadomość
              <Field id="message" name="message" as="textarea" required />
            </StyledLabel>
          </FormWrapperRight>
          {openErrorMsg && (
            <ErrorMessage>
              Zaznacz pole ReCAPTCHA aby wysłać wiadomość!
            </ErrorMessage>
          )}
          <ReCAPTCHAWrapper>
            <ReCAPTCHA
              sitekey="6LdY4uMaAAAAAD1t3HghIwS9xYj_EaeKZnueFo92"
              onChange={isReCAPTCHAVerifed}
              onExpired={isReCAPTCHAExpired}
              render="onload"
            />
          </ReCAPTCHAWrapper>
          <ButtonWrapper>
            <StyledButton type="submit">
              Wyślij
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.475"
                height="14.979"
                viewBox="0 0 17.475 14.979"
              >
                <path
                  id="ic_send_24px"
                  d="M2.008,17.979l17.467-7.489L2.008,3,2,8.825l12.482,1.664L2,12.154Z"
                  transform="translate(-2 -3)"
                  fill="#59b4d0"
                />
              </svg>
            </StyledButton>
          </ButtonWrapper>
          <FormWrapperBottom>
            <StyledLabel htmlFor="agreement" $isCheckbox>
              <Field
                id="agreement"
                name="agreement"
                as="input"
                type="checkbox"
                required
              />
              Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z
              ustawą o ochronie danych osobowych w związku z wysłaniem zapytania
              przez formularz kontaktowy. Podanie danych jest dobrowolne, ale
              niezbędne do przetworzenia zapytania
            </StyledLabel>
            <StyledLabel htmlFor="agreement2" $isCheckbox $isNotRequired>
              <Field
                id="agreement2"
                name="agreement2"
                as="input"
                type="checkbox"
              />
              Wyrażam zgodę na przetwarzanie moich danych osobowych w celu
              (np.Wysyłania informacji handlowej). Podanie danych osobowych jest
              dobrowolne.Zostałem poinformowany, że przysługuje mi prawo dostępu
              do swoich danych,możliwości ich poprawiania, żądania zaprzestania
              ich przetwarzania. Administratorem danych jest BBE inwestycje.
            </StyledLabel>
          </FormWrapperBottom>
          <ButtonWrapperMobile>
            <StyledButton type="submit">
              Wyślij
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.475"
                height="14.979"
                viewBox="0 0 17.475 14.979"
              >
                <path
                  id="ic_send_24px"
                  d="M2.008,17.979l17.467-7.489L2.008,3,2,8.825l12.482,1.664L2,12.154Z"
                  transform="translate(-2 -3)"
                  fill="#59b4d0"
                />
              </svg>
            </StyledButton>
          </ButtonWrapperMobile>
        </Form>
      </Formik>
      <StyledRequiredInfo>
        Pola oznaczone <span>*</span> są wymagane.
      </StyledRequiredInfo>
    </FormWrapper>
  );
};

const FormSubmited: FC<{ onClick?: () => void }> = ({ onClick }) => {
  return (
    <FormSubmitedWrapper>
      <Paragraph>Wiadomość wysłana pomyślnie!</Paragraph>
      <FormButtonClose onClick={onClick}>Zamknij</FormButtonClose>
    </FormSubmitedWrapper>
  );
};

export default ContactForm;
